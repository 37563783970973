import React, { useEffect, useState } from 'react'
import styles from './BlogManagement.module.scss'
import { Box, Button, Divider, Modal } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FaEdit, FaTrashAlt } from "react-icons/fa"
import Query from '../../query';
import ARTICLES_QUERY from '../../../queries/articles/Articles';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux'
import useFetchCollectionBlogs from '../../../customHook/useFetchCollectionBlogs'
import { STORE_BLOGS } from '../../../redux/slice/utilSlice'
import { Timestamp, addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'

const BlogManagement = () => {

    const {blogs, isLoadingBlogs} = useFetchCollectionBlogs('blogs');
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(STORE_BLOGS({blogs: blogs}));
      },[dispatch, blogs])

    const createBlog = slug => {
        const blogsConfig = {
            slug: slug,
            createdAt: Timestamp.now().toDate(),
            itemOnBlog: [],
        }
      
        try{
            setDoc(doc(db, "blogs", slug), blogsConfig)
        } catch (error) {
            console.log(error)
        }
    }

    const editBlog = slug => {
      navigate(`/admin/blogmanagement/editblog/${slug}`)
    }


    const handleRedirectBlog = () => {
        window.location.replace('https://cms.blugainvillea.com/admin')
    };
    
  return (
    <div className={styles.blogmanagement}>
        <h4 style={{paddingBottom: 10}}>Blog Management</h4>
        <Button variant='contained' onClick={handleRedirectBlog}>
            open dashboard
        </Button>

        <Query query={ARTICLES_QUERY} id={null}>
                {({ data: { articles } }) => {
                    return (
                        articles.data.map(({ id, attributes }) => (
                            <div className={styles.content} key={attributes.slug}>
                                <Divider />
                                <p>{dayjs(attributes.publishedAt).format('DD/MM/YYYY')}</p>
                                <div>
                                    <p>{attributes.slug}</p>
                                </div>
                                <div className={styles.icons}>
                                    {blogs.findIndex(item => item.slug === attributes.slug) === -1 ? (
                                        <Button variant='contained' onClick={()=> createBlog(attributes.slug)}>
                                            create blog
                                        </Button>
                                    ) : (
                                        <span onClick={() => editBlog(attributes.slug)}>
                                            <FaEdit size={20} color="black" />
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))
                    );
                }}
        </Query>
        

        
    </div>
  )
}

export default BlogManagement
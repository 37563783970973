import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck , ReCaptchaV3Provider} from "firebase/app-check";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "blugainvillea-com.firebaseapp.com",
  projectId: "blugainvillea-com",
  storageBucket: "blugainvillea-com.firebasestorage.app",
  messagingSenderId: "31397487813",
  appId: "1:31397487813:web:b1087b9e0c5cdb3fb23cac",
  measurementId: "G-657M6EDKVT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app)
export const storage = getStorage(app);

export default app

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Le-LaQpAAAAAJhCBRBmIp5SwirEJjgUGo2_aGOw'),
  isTokenAutoRefreshEnabled: true,
});
